import React from "react"
import PropTypes from "prop-types"

const PrivacyAndPolicy = props => {
  let data = props.data
  return (
    <div>
      <div className="parent_container_desktop">
        <div
          className="terms_desktop_body"
          dangerouslySetInnerHTML={{
            __html: data.nodes[0].field_privacy_page_body.processed,
          }}
        />
      </div>
    </div>
  )
}

PrivacyAndPolicy.propTypes = {
  data: PropTypes.string.isRequired,
}

class PrivacyPolicy extends React.Component {
  propTypes = {
    data: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div>
        <PrivacyAndPolicy data={this.props.data} />
      </div>
    )
  }
}

export default PrivacyPolicy
