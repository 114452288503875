import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicy from "../components/privacyPolicy"
import PropTypes from "prop-types"

let propTypes = {
  data: PropTypes.string.isRequired,
}

export default function PrivacyPage(props) {
  return (
    <Layout>
      <SEO
        title="Privacy Policy - Tata Studi | Tata ClassEdge"
        description="We respect your privacy, we protects your personal data 
      in accordance with applicable laws and our data privacy policies. 
      Please go through the policy provided by Tata Studi, it explains 
      who we are, how we collect, share and use Personal Information."
        lang="en"
      />
      <div className="pageBannerHeading">
        <h1 className="displayNone">Privacy Policy</h1>
        <h2>Privacy Policy</h2>
      </div>
      <PrivacyPolicy data={props.data.privacyData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    privacyData: allNodePrivacyPolicy {
      nodes {
        field_privacy_page_body {
          processed
        }
      }
    }
  }
`
